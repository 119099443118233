<template>
  <div class="NavigationBar TopBarWrap TopBarNoShadow TopBarWrapperFullWidth">
    <nav
      class="TopBar top-bar"
      :class="{ expanded: expandNav }">
      <ul class="title-area">
        <li class="name">
          <a
            href="/"
            title="Logotype"
            class="logo-header">
            <img
              :src="logotypeDark"
              class="logotype"
              alt="Grain">
            <img
              :src="logotypeLight"
              class="logotype white"
              alt="Grain">
          </a>
        </li>
        <img
          src="@/assets/images/icons/icon-hamburger-menu.svg"
          class="toggle-topbar menu-icon"
          width="22"
          @click="openCloseMobileMenu">
      </ul>
      <section class="top-bar-section">
        <ul class="left">
          <template v-for="(nav, index) in navigations.left.navs">
            <li
              v-if="nav.label"
              :key="`${navigations.left.key}-${index}`">
              <component
                :is="nav.route ? 'router-link': 'a'"
                :id="nav.id"
                :to="nav.route"
                :href="nav.href || nav.route"
                :target="nav.target"
                :data-method="nav.method"
                @click="navClick(nav.id)">
                <component :is="isCateringHomePage(nav) ? 'h1' : 'div'">
                  {{ nav.label }}
                  <span
                    v-if="nav.tag"
                    class="navbar-link-tag"
                    v-html="nav.tag"/>
                </component>
              </component>
            </li>
          </template>
          <div class="clear"/>
        </ul>
        <ul class="right">
          <template v-for="(nav, index) in navigations.right.navs">
            <li
              v-if="nav.label"
              :key="`${navigations.right.key}-${index}`">
              <a
                :id="nav.id"
                :href="nav.href"
                :target="nav.target"
                :data-method="nav.method"
                @click="navClick(nav.id)"
                v-html="nav.label"/>
            </li>
          </template>
          <div class="clear"/>
        </ul>
      </section>
    </nav>
    <loading-overlay v-if="loadingOverlayVisible"/>
  </div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay'
import UserApi from '@/api/UserApi'
import cookies from '@/assets/js/cookies'
import { mapGetters } from 'vuex'

export default {
  name: 'navigation-bar',
  components: {
    LoadingOverlay
  },
  props: {
    useCateringContactNumber: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      expandNav: false,
      loadingOverlayVisible: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'features'
    ]),
    logotypeDark() {
      return require('@/assets/images/grain-logotype.png')
    },
    logotypeLight() {
      return require('@/assets/images/grain-logotype-white.png')
    },
    isAuthenticated() {
      return !!cookies.getCookie('external_access_token')
    },
    navigations() {
      const leftNavs = [
        {
          label: this.$t('navbar.rte'),
          href: '/eat'
        }
      ]
      if (this.features.showMealPlans) {
        leftNavs.push({
          label: this.$t('navbar.mealPlans'),
          href: '/meal-plans',
          tag: this.$t('common.betaTag')
        })
      }
      if (this.features.showCustomNavBeforeCatering && (this.features.showCustomNavBeforeCatering.navs || []).length > 0) {
        this.features.showCustomNavBeforeCatering.navs.map((nav) => {
          leftNavs.push({
            label: nav.label,
            href: nav.link,
            target: nav.target !== undefined ? nav.target : '_blank'
          })
        })
      }
      leftNavs.push({
        label: this.$t('navbar.catering'),
        route: '/catering'
      })
      if (this.features.showCustomNav && (this.features.showCustomNav.navs || []).length > 0) {
        this.features.showCustomNav.navs.map((nav) => {
          leftNavs.push({
            label: nav.label,
            href: nav.link,
            target: nav.target !== undefined ? nav.target : '_blank'
          })
        })
      }
      const rightNavs = [
        {
          label: this.contactNumber,
          href: this.contactNumberLink
        }
      ]
      if (this.isAuthenticated) {
        rightNavs.push(
          {
            label: this.$t('navbar.account'),
            href: '/account/profile'
          },
          {
            label: this.$t('navbar.logout'),
            id: 'nav_logout'
          }
        )
      } else if (!location.pathname.includes('/select-menu-item')) {
        rightNavs.push(
          {
            label: this.$t('navbar.login'),
            id: 'nav_login'
          }
        )
      }
      return {
        left: {
          navs: leftNavs,
          key: 'NavCenter'
        },
        right: {
          navs: rightNavs,
          key: this.isAuthenticated ? 'RightLogout' : 'RightLogin'
        }
      }
    },
    contactNumber() {
      // rename all to Contact us for now
      return this.useCateringContactNumber ? this.$t('navbar.contactUs') : this.$t('navbar.contactUs')
    },
    contactNumberLink() {
      // reroute all to contact page for now
      return this.useCateringContactNumber ? this.$t('navbar.contactUsLink') : this.$t('navbar.contactUsLink')
    }
  },
  created() {
    this.fetchFeatures()
  },
  methods: {
    async fetchFeatures() {
      try {
        const result = (await UserApi.fetchFeatures())
        this.$store.dispatch('user/setFeatures', result.features)
      } catch(e) {}
    },
    isCateringHomePage(nav) {
      return nav.route === '/catering' && location.pathname === '/catering'
    },
    navClick(id) {
      switch (id) {
        case 'nav_login':
          window.location.href = '/login'
          break
        case 'nav_logout':
          this.logout()
          break
      }
    },
    openCloseMobileMenu() {
      this.expandNav = !this.expandNav
    },
    async logout() {
      this.loadingOverlayVisible = true
      await UserApi.logout()
      cookies.deleteCookie('access_level')
      cookies.deleteCookie('external_access_token')
      cookies.deleteCookie('session_user_uuid')
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/navigation";
@import "~@/assets/css/navigation_additional";

$navBarHeight: 60px;
$iPadBreak: 960px;

.NavigationBar {
  float: left;
  line-height: $navBarHeight;
  height: $navBarHeight;

  &.TopBarNoShadow {
    @media screen and (max-width: $iPadBreak) {
      box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, .2)
    }
  }

  .TopBar {
    line-height: $navBarHeight;
    height: $navBarHeight;

    &.expanded {
      height: auto;

      @media screen and (min-width: $iPadBreak) {
        box-shadow: none;
      }
    }

    ul.title-area {
      float: left;
      position: relative;
      z-index: 99;

      @media screen and (max-width: $iPadBreak) {
        width: 100%;
      }

      li.name {
        line-height: $navBarHeight;
        height: $navBarHeight;

        .logo-header {
          margin-right: $space-m;
          line-height: $navBarHeight;

          .logotype {
            height: 18px;

            &.white {
              display: none;
            }
          }
        }
      }
    }

    section.top-bar-section {
      text-align: center;

      @media screen and (min-width: $iPadBreak) {
        line-height: $navBarHeight;
        height: $navBarHeight;
      }

      ul {
        position: initial;
        text-align: left;

        &.left,
        &.right {
          .navbar-link-tag {
            padding: 1px $space-xxs;
            font-weight: normal;
            vertical-align: text-top;
            border: 1px solid $ink;
            border-radius: $round;
            @extend %small;
          }
        }

        &.left {
          float: left;

          @media screen and (min-width: $iPadBreak) {
            display: inline-block;
            margin: auto;

            li {
              display: inline-block;

              @media screen and (max-width: $iPadBreak) {
                display: none;
              }

              a {
                padding: 0 $space-s;

                h1 {
                  line-height: $navBarHeight;
                }
              }
            }
          }

          a h1 {
            font-size: $font-size-p;
            font-weight: bold;
            line-height: $line-height-h5;
          }
        }

        &.right {
          @media screen and (min-width: $iPadBreak) {
            position: absolute;
            right: 0;

            li {
              padding-left: 1.5rem;
              padding-right: 0;
            }
          }
        }
      }

      li {
        @media screen and (min-width: $iPadBreak) {
          line-height: $navBarHeight;

          a {
            font-size: 0.875rem;
            line-height: $navBarHeight;
          }
        }
      }
    }
  }

  &.White {
    .TopBar:not(.expanded) {
      ul.title-area {
        li.name {
          .logo-header {
            .logotype {
              display: none;

              &.white {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .navbar-link-tag {
      border: 1px solid $white !important;
    }
  }
}

.AddressBoxWrap {
  .LocationIcon {
    float: left;
  }

  .MenuDate {
    float: left;
  }

  .picker-caret-pad {
    float: left;
  }
}
</style>
